
$fontText: 'Helvetica', 'Arial', 'sans-serif';

$dark: #212322;
$red: #E20719;
$light: #F0F0EF;
$blue: #BACDD5;

::selection {
  background-color: $red;
  color: #fff;
}

::-moz-selection {
  background-color: $red;
  color: #fff;
}
