.tiny-title {
	text-transform: uppercase;
	font-family: Acumin Pro, sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	padding-left: 65px;
	position: relative;
	letter-spacing: 1px;

	&::before {
		content: '';
		display: block;
		width: 50px;
		height: 5px;
		background-color: $red;
		position: absolute;
		left: 0;
		top: 6px;
	}

	@media only screen and (max-width: 1024px) {
		font-size: 14px;
	}
	@media only screen and (max-width: 500px) {
		font-size: 12px;
	}
}

.big-title {
	font-family: Acumin Pro, sans-serif;
	font-weight: 800;
	font-style: normal;
	font-size: 45px;
	text-transform: uppercase;
	line-height: 1.2;

	@media only screen and (max-width: 1024px) {
		font-size: 35px;
	}
	@media only screen and (max-width: 500px) {
		font-size: 25px;
	}
}

.medium-title {
	font-family: Acumin Pro, sans-serif;
	font-weight: 300;
	letter-spacing: 1px;
	font-style: normal;
	font-size: 30px;
	text-transform: uppercase;
	line-height: 1.2;
	@media only screen and (max-width: 1024px) {
		font-size: 20px;
	}
	@media only screen and (max-width: 500px) {
		font-size: 18px;
	}
}

.regular-text {
	font-family: Acumin Pro, sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 2;
	@media only screen and (max-width: 1024px) {
		font-size: 14px;
		line-height: 1.5;
	}
}
