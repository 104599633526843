.hero {
	position: relative;
	width: 100%;

	&.home {
		height: 100vh;
	}

	&.about {
		height: 75vh;
		background-image: url('/img/hero-about.jpg');
		background-size: cover;
		background-position: center center;

		.data {
			top: 55%;
		}

		.letter-r {
			position: absolute;
			right: 0px;
			bottom: 0vw;
			width: 20vw;
			z-index: 10;
		}
	}

	&.produits-services {
		height: 75vh;
		background-image: url('/img/hero-services.jpg');
		background-size: cover;
		background-position: center center;

		.data {
			top: 55%;
		}
	}

	&.service-details {
		height: 75vh;
		background-image: url('/img/service-commercial.jpg');
		background-size: cover;
		background-position: center center;

		.data {
			top: 55%;
		}
	}

	.main-carousel {
		width: 100%;
		height: 100vh;
		position: relative;

		.carousel-cell {
			width: 100%;
			height: 100vh;
			background-size: cover;
			background-position: center center;
		}

		.hero-slider-1 {
			background-image: url('/img/hero-home.jpg');
		}
		.hero-slider-2 {
			background-image: url('/img/home-fierte.jpg');
		}
		.hero-slider-3 {
			background-image: url('/img/hero-home.jpg');
		}

		/* position dots in carousel */
		.flickity-page-dots {
		  right: 0px;
		  top: 50%;
		  bottom: inherit;
		  transform: translate(0, -50%);
		  z-index: 100;
		  width: auto;
		  text-align: right;
		  display: flex;
		  flex-wrap: wrap;
		  justify-content: flex-end;
		  width: 40px;
		}
		/* white circles */
		.flickity-page-dots .dot {
		  width: 25px;
		  height: 4px;
		  opacity: 1;
		  background: $light;
		  display: block;
		  margin: 0;
		  border-radius: 0;
		  margin-bottom: 15px;
		  transition: all 0.2s ease;
		}
		/* fill-in selected dot */
		.flickity-page-dots .dot.is-selected {
		  width: 40px;
		}
	}

	.data {
		position: absolute;
		top: 50%;
		left: 5vw;
		transform: translate(0, -50%);
		z-index: 10;
		color: $light;
		width: 70%;

		.big-title {
			margin: 25px 0;
		}
	}

	.safe {
		width: 20vw;
		max-width: 110px;
		position: absolute;
		right: 5vw;
		bottom: 5vw;
		z-index: 10;
	}

	&.about {
		.safe {
			width: 20vw;
			max-width: 110px;
			position: absolute;
			right: inherit;
			left: 5vw;
			bottom: 50px;
			z-index: 10;
		}
	}

	.opacity {
		width: 100%;
		height: 100%;
		background-color: $dark;
		opacity: 0.7;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	@media only screen and (max-width: 500px) {
		.data {
			left: 8vw;
		}
		&.home {
			height: 80vh;
		}
	}
}

.hero-sub-menu {
	background-color: #fff;
	position: absolute;
	right: 5vw;
	bottom: 0;
	z-index: 50;
	padding: 2vw;
	a {
		text-decoration: none;
		text-transform: uppercase;
		color: $dark;
		font-size: 14px;
		margin: 0 20px;
		transition: all 0.2s ease;
		letter-spacing: 1px;

		&:hover {
			color: $red;
		}
	}

	@media only screen and (max-width: 800px) {
		display: none;
	}
}

.half-block {
	display: flex;
	align-items: center;
	width: 100%;

	&.services {
		margin: 5vw 0vw;
	}

	.img-block {
		width: 50%;
		position: relative;
		z-index: 10;

		video {
			width: 100%;
			height: auto;
		}

		img {
			width: 100%;

			&.play {
				width: 80px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(1,1);
				cursor: pointer;
				transition: all 0.2s ease;

				&:hover {
					transform: translate(-50%, -50%) scale(0.9,0.9);
				}
			}
		}
	}

	.data {
		width: 50%;
		padding: 0 5vw;
		&.spacing {
			padding: 0 5vw 0 8vw;
		}
		.regular-text {
			margin-bottom: 25px;
		}
		.medium-title {
			color: $dark;
			line-height: 1.5;
			margin: 25px 0;
		}
	}

	&.special-hero {
		.img-block {
			margin-top: -2vw;
		}
		.data {
			.medium-title {
				text-transform: none;
			}
		}
	}

	&.special-style {
		position: relative;
		margin-top: 12vw;
		&:before {
			content:'';
			display: block;
			width: 5vw;
			height: 30vw;
			background-color: $blue;
			position: absolute;
			top: -10vw;
			left: 0;
			z-index: 20;
		}
		.letter-s {
			position: absolute;
			left: 0px;
			top: -20vw;
			width: 18vw;
			z-index: 1;
		}
	}

	&.spacing {
		margin: 5vw 0;
		.img-block {
			padding-right: 8vw;
		}
		.data {
			padding: 0 5vw 0 8vw;
			.medium-title {
				padding-right: 10vw;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		.img-block {
			width: 100%;
			img {
				&.play {
					width: 50px;
				}
			}
		}
		.data {
			width: 100%;
			padding: 8vw;
		}
		&.special-hero {
			.img-block {
				margin-top: 0;
			}
		}
		&.spacing .img-block {
			padding-right: 0;
		}
		&.services {
			.img-block {
				margin-top: 8vw;
			}
		}
	}
}

.services-block {
	width: 100%;
	background-image: url('/img/home-services.jpg');
	background-size: cover;
	background-position: center center;
	display: flex;
	position: relative;
	margin-top: 10vw;

	.service {
		width: 33.33%;
		min-height: 80vh;
		position: relative;
		z-index: 10;
		text-decoration: none;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 1px solid rgba(255,255,2550,0.3);

		.medium-title {
			color: $light;
			padding: 0 10vw;
			text-align: center;
			font-weight: 300;
		}

		&:last-child {
			border-right: 0;
		}

		.plus {
			position: absolute;
			left: 50%;
			bottom: 30px;
			transform: translate(-50%,0);
		}

		&:hover {
			.plus {
				p {
					color: $red;
				}
				&::before {
					width: 100%;
				}
			}
		}
	}

	.service-commercial:hover ~ .service-commercial-img {
		opacity: 1;
	}

	.service-residentiel:hover ~ .service-residentiel-img {
		opacity: 1;
	}

	.service-commercial-img {
		width: 100%;
		height: 100%;
		background-image: url('/img/service-commercial.jpg');
		background-size: cover;
		background-position: center center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0;
		transition: all 0.5s ease;
	}

	.service-residentiel-img {
		width: 100%;
		height: 100%;
		background-image: url('/img/service-residentiel.jpg');
		background-size: cover;
		background-position: center center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0;
		transition: all 0.5s ease;
	}

	.opacity {
		width: 100%;
		height: 100%;
		background-color: $dark;
		opacity: 0.7;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}

	.letter-r {
		position: absolute;
		right: 0px;
		top: -20vw;
		width: 20vw;
		z-index: 1;
	}

	@media only screen and (max-width: 500px) {
		flex-wrap: wrap;

		.service {
			width: 100%;
			min-height: 30vh;
			border-right: 0;
			border-bottom: 1px solid rgba(255,255,2550,0.3);

			&:last-child {
				border-bottom: 0;
			}

			.medium-title {
				position: relative;
				top: -10%;
			}

			.plus {
				bottom: 20px;
			}
		}
	}
}

.realisations {
	width: 100%;
	padding: 5vw 8vw;
	position: relative;

	header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 5vw;

		.medium-title {
			margin-top: 25px;
		}

		.regular-text {
			width: 49%;
		}

		.right {
			&.filters-wrap {
				position: relative;
				.button {
					width: auto;
					min-width: 230px;
				}
				.filters {
					background-color: $dark;
					width: 100%;
					position: absolute;
					top: 55px;
					left: 0;
					z-index: 10;
					overflow: hidden;
					height: 0;
					transition: all 0.5s ease;
					&.show {
						height: 287px;
					}
					.filter {
						color: $light;
						font-size: 15px;
						padding: 10px;
						border-bottom: 1px solid rgba(255,255,255,0.1);
						cursor: pointer;
						transition: all 0.2s ease;

						a {
							text-decoration: none;
							color: inherit;
						}

						&:hover {
							color: $red;
						}
					}
				}
			}
		}
	}

	.list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;

		.row {
			width: calc(50% - 10px);
		}

		.regular-text {
			margin-bottom: 2vw;
		}

		.spacing-bottom {
			margin-bottom: 3vw;
		}

		.projet {
			width: 100%;
			position: relative;
			overflow: hidden;
			display: inline-block;
			margin-bottom: 20px;

			.plus {
				position: absolute;
				right: 25px;
				bottom: 25px;
				z-index: 2;
			}

			.name {
				font-weight: 800;
				font-size: 24px;
				position: absolute;
				top: 25px;
				left: 25px;
				text-transform: uppercase;
				width: 60%;
				line-height: 1.1;
				z-index: 2;
				color: $light;
				opacity: 0;
				transition: all 0.5s ease;
			}

			img {
				transform: scale(1,1);
				transition: all 0.8s ease;
			}

			&:before {
				content:"";
				display: block;
				width: 100%;
				height: 100%;
				background-color: $dark;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				z-index: 1;
				transition: all 0.5s ease;
			}

			&:hover {
				&:before {
					opacity: 0.7;
				}
				.name {
					opacity: 1;
				}
				img {
					transform: scale(1.05,1.05);
				}
				.plus {
					p {
						color: $red;
					}
					&::before {
						width: 100%;
					}
				}
			}
		}
	}

	.letter-t {
		position: absolute;
		right: 0px;
		bottom: -5vw;
		width: 20vw;
		z-index: 1;
	}

	@media only screen and (max-width: 1024px) {
		.list .projet .name {
			font-size: 20px;
		}
		header {
			margin-bottom: 5vw;
		}
		.list {
			.regular-text {
				margin-bottom: 5vw;
			}
		}
	}
	@media only screen and (max-width: 600px) {
		padding: 8vw;
		padding-bottom: 20vw;

		.list {
			.row {
				width: 100%;
			}
			.projet {
				margin-bottom: 3vw;
			}
		}
	}
}
