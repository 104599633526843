.address-block {
	width: 100%;
	padding: 5vw 8vw;
	display: flex;
	align-items: center;

	.data {
		width: 50%;

		.address {
			.line {
				width: 50px;
				height: 5px;
				background-color: $red;
				margin: 1vw 0;
			}
			a {
				text-decoration: none;
				color: $dark;
				margin-bottom: 10px;
				display: inline-block;
				transition: all 0.2s ease;
				&:hover {
					color: $red;
				}
			}
			&:last-child {
				margin-top: 2vw;
			}
		}
	}

	.map-wrapper {
		position: relative;
		width: 50%;

		.map-ph {
			opacity: 0;
		}

		.map {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: #e2e2e2;
		}
	}

	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		.data {
			width: 100%;
			order: 1;

			.address {
				margin-bottom: 5vw;
				.line {
					margin: 3vw 0;
				}
			}
		}
		.map-wrapper {
			width: 100%;
			order: 0;
			margin-bottom: 5vw;
		}
	}
}

.form-block {
	width: calc(100% - 16vw);
	background-color: $dark;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	padding: 5vw;
	position: relative;
	margin-bottom: 10vw;

	.data {
		width: 45%;
		.medium-title {
			color: $light;
			margin-bottom: 2vw;
		}
		.regular-text {
			color :$light;
		}
	}

	.form {
		width: 45%;

		.input-style {
			width: 100%;
			height: 50px;
			border-radius: 3px;
			margin-bottom: 5px;
			padding: 15px;
			border: 0;
			background-color: $light;
			font-family: acumin-pro, sans-serif !important;
			color: $dark;
			-webkit-appearance: none;

			&.textarea {
				min-height: 220px;
				max-height: 220px;
			}
		}

		select.input-style {
			background-image: url(/img/arrow-down.svg);
	    background-position: calc(100% - 15px) center;
	    background-repeat: no-repeat;
	    padding-right: 40px;
		}

		.button {
			cursor: pointer;
			margin-top: 1vw;
		}
	}

	.img {
		width: 53%;
		max-width: 720px;
		position: absolute;
		left: -8vw;
		bottom: -8vw;
	}

	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		.data {
			width: 100%;
			margin-bottom: 5vw;
		}
		.form {
			width: 100%;
		}
		.img {
			display: none;
		}
	}
}

.form-message {
	display: block;
	margin: 0;
	margin-bottom: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 50px;
  line-height: 1;
  color: green;

  &.error {
    color: red;
  }
}
