.blog-list {
	width: 100%;
	background-color: $light;
	padding: 5vw 8vw;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.medium-title {
		width: 100%;
		margin-bottom: 3vw;
	}

	.blog {
		width: calc(50% - 10px);
		text-decoration: none;
		margin-bottom: 20px;

		.img {
			overflow: hidden;
			img {
				transform: scale(1,1);
				transition: all 0.5s ease;
			}
		}

		.data {
			background-color: #fff;
			color: $dark;
			padding: 3vw;
			position: relative;

			.small-title {
				font-size: 24px;
				font-weight: 300;
				line-height: 1.2;
				margin-bottom: 2vw;
			}

			.regular-text {
				line-height: 1.5;
				width: 80%;
			}

			.plus {
				position: absolute;
				right: 3vw;
				bottom: 3vw;
				border: 1px solid $dark;
				p {
					color: $red;
				}
				&::before {
					background-color: $dark;
				}
			}
		}

		&:hover {
			.img {
				img {
					transform: scale(1.1,1.1);
				}
			}
			.data {
				.plus {
					&::before {
						width: 100%;
					}
				}
			}
		}
	}
	@media only screen and (max-width: 1024px) {
		.blog .data .small-title {
			font-size: 18px;
		}
	}

	@media only screen and (max-width: 800px) {
		.blog {
			width: 100%;
		}
	}

	@media only screen and (max-width: 500px) {
		.blog {
			.data {
				padding: 8vw 5vw;
			}
		}
	}
}

.blog-details {
	background-color: #fff;
	padding: 5vw 20vw;

	h1,h2,h3,h4,h5,h6 {
		margin-bottom: 2vw;
		color: $dark;
	}

	h1 {
		font-size: 30px;
		font-weight: 800;
	}
	h2 {
		font-size: 24px;
		font-weight: 800;
	}
	h3 {
		font-size: 22px;
		font-weight: 600;
	}
	h4 {
		font-size: 20px;
		font-weight: 400;
	}
	h5 {
		font-size: 18px;
		font-weight: 400;
	}
	h6 {
		font-size: 18px;
		font-weight: 300;
	}
	.regular-text,
	p {
		margin-bottom: 2vw;
		font-family: Acumin Pro, sans-serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 2;
	}

	ul {
		padding-left: 20px;
		margin-bottom: 2vw;
		li {
			font-size: 18px;
			color: $dark;
			line-height: 2;
			list-style-type: disc;
		}
	}

	img {
		margin-bottom: 2vw;
	}

	.video-container {
	    overflow: hidden;
	    position: relative;
	    width:100%;
	    margin-bottom: 2vw;
	}

	.video-container::after {
	    padding-top: 56.25%;
	    display: block;
	    content: '';
	}

	.video-container iframe {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100% !important;
	    height: 100% !important;
	}

	@media only screen and (max-width: 800px) {
		padding: 10vw 8vw;
	}
}
