.produits-block {
	width: 100%;
	padding-top: 5vw;
	.arrow {
		background-color: $light !important;
	}
	.main-carousel {
		width: 100%;
		margin-top: 3vw;
		padding-left: 8vw;

		.carousel-cell {
			width: 29%;
			margin-right: 20px;
			transition: all 0.2s ease;
			position: relative;
			text-decoration: none;

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 70%;
				background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 1;
			}

			.name {
				margin-bottom: 2vw;
				p {
					font-size: 16px;
					font-weight: 400;
					letter-spacing: 1px;
					color: $dark;
				}
			}

			.plus {
				position: absolute;
				right: 20px;
				bottom: 20px;
				z-index: 10;
			}

			&:hover {
				.plus {
					p {
						color: $red;
					}
					&::before {
						width: 100%;
					}
				}
			}

			&.no-margin {
				margin-right: 0;
			}

			&.spacing {
				width: 8vw;
				margin-right: 0;
			}
		}
	}

	@media only screen and (max-width: 1000px) {
		.main-carousel {
			.carousel-cell {
				width: 40%;
			}
		}
	}

	@media only screen and (max-width: 650px) {
		.main-carousel {
			.carousel-cell {
				width: 50%;
			}
		}
	}
	@media only screen and (max-width: 500px) {
		.main-carousel {
			.carousel-cell {
				width: 80%;
			}
		}
	}
}

.services-list-block {
	width: 100%;
	padding-top: 5vw;

	.slider-header {
		margin-bottom: 3vw;
	}

	&.small-margin-top {
		.service {
			margin-top: 5vw !important;
		}
	}

	.service {
		width: calc(100% - 16vw);
		position: relative;
		margin: 0 auto;

		&:after {
			clear: both;
			content: "";
			display: block;
		}

		.img {
			width: 55%;
			position: relative;
			z-index: 1;
		}

		.data {
			width: 55%;
			background-color: #fff;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 70px;
			z-index: 10;

			.regular-text {
				margin: 2vw 0;
			}
		}

		&:last-child {
			margin-top: 5vw;
			margin-bottom: 5vw;
		}

		&.left {
			.img {
				float: left;
			}
			.data {
				position: absolute;
				top: 5vw;
				right: 0;
			}
		}

		&.right {
			.img {
				float: right;
			}
			.data {
				position: absolute;
				top: -3vw;
				left: 0;
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		.service {
			margin-bottom: 20vw;
		}
	}

	@media only screen and (max-width: 800px) {
		.service {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 5vw;
			.img {
				width: 100%;
				order: 0;
			}
			.data {
				width: 100%;
				order: 1;
			}
			&.left {
				.img {
					float: none;
				}
				.data {
					position: relative;
					top: 0;
				}
			}

			&.right {
				.img {
					float: none;
				}
				.data {
					position: relative;
					top: 0;
				}
			}
			&:last-child {
				margin-top: 5vw;
			}
		}
	}
}