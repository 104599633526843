.button {
	text-decoration: none;
	display: flex;
	align-items: center;
	width: fit-content;
	padding: 20px;
	background-color: transparent;
	transition: all 0.2s ease;
	position: relative;


	p {
		margin-right: 30px;
		text-transform: uppercase;
		font-size: 14px;
		transition: all 0.2s ease;
		z-index: 10;
	}

	&.is-left {
		p {
			margin-left: 30px;
			margin-right: 0;
		}
	}

	&::before {
		content: '';
		display: block;
		width: 0%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		transition: all 0.2s ease;
	}

	&.arrow-down {
		width: 230px;
		justify-content: space-between;
		img {
			width: 10px;
		}
	}

	img {
		width: 15px;
		z-index: 10;
	}

	&.light {
		border: 1px solid $light;
		p {
			color: $light;
		}
		&::before {
			background-color: $light;
		}
	}

	&.dark {
		border: 1px solid $dark;
		p {
			color: $dark;
		}
		&::before {
			background-color: $dark;
		}
	}

	&:hover {
		&.light {
			p {
				color: $dark;
			}
			&::before {
				width: 100%;
				background-color: $light;
			}
		}
		&.dark {
			p {
				color: $light;
			}
			&::before {
				width: 100%;
				background-color: $dark;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		padding: 15px;

		p {
			font-size: 12px;
		}
	}
}

.plus {
	width: 45px;
	height: 45px;
	border: 1px solid $light;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	p {
		color: $light;
		font-weight: 300;
		margin: 0;
		padding: 0;
		font-size: 18px;
		line-height: 1;
		position: relative;
		top: -2px;
		z-index: 10;
	}
	&::before {
		content: '';
		display: block;
		width: 0%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		transition: all 0.2s ease;
		background-color: $light;
	}
	&:hover {
		p {
			color: $red;
		}
		&::before {
			width: 100%;
		}
	}
	@media only screen and (max-width: 800px) {
		width: 35px;
		height: 35px;
	}
}

.arrow {
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 15px;
	cursor: pointer;
	position: relative;
	img {
		position: relative;
		width: 10px;
		z-index: 10;
	}

	&.dark {
		border: 1px solid $dark;
		background-color: #fff;
		&:before {
			background-color: $dark;
		}
	}

	&.light {
		border: 1px solid $light;
		background-color: $dark;
		&:before {
			background-color: $light;
		}
	}

	&:before {
		content: '';
		display: block;
		width: 0;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 0.2s ease;
	}

	&:hover {
		&:before {
			width: 100%;
		}
	}
	@media only screen and (max-width: 800px) {
		width: 35px;
		height: 35px;

		img {
			width: 7px;
		}
	}
}


.pagination-wrap {
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

  padding-top: 4vw;
  padding-bottom: 1vw;

  a {
    margin: 10px 15px;
  }
}
