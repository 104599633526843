.jobs-block {
	width: 100%;
	background-color: $light;
	padding: 10vw 8vw 8vw 8vw;

	.medium-title {
		margin-bottom: 3vw;
	}

	.job {
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 2vw;
		text-decoration: none;
		margin-bottom: 1vw;
		transition: all 0.2s ease;

		.regular-text {
			color: $dark;
			transition: all 0.2s ease;
		}

		.right {
			display: flex;
			align-items: center;

			img {
				width: 20px;
				margin-left: 20px;
			}
		}

		&:hover, &.actif {
			background-color: $dark;
			.regular-text {
				color: $light;
			}
		}
	}

	@media only screen and (max-width: 900px) {
		.job {
			padding: 3vw;
		}
	}
	@media only screen and (max-width: 600px) {
		.job {
			flex-wrap: wrap;

			.left {
				width: 100%;
				margin-bottom: 2vw;

				.regular-text {
					font-weight: 500;
					font-size: 16px;
				}
			}

			.right {
				width: 100%;
				justify-content: space-between;
			}
		}
	}
}
