.main-header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 10000;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 40px 5vw 0 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;

  .logo {
    width: 125px;
    opacity: 1;
    transition: all 0.2s ease;
  }

  .logo-colors {
    width: 100px;
    display: none;
  }

  a {
    text-decoration: none;
    color: $light;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.2s ease;

    &.is-active {
      color: $red;
    }
  }

  nav {
    position: relative;
    right: -5%;
    a {
      margin: 0 10px;
      font-size: 14px;

      &:hover {
        color: $red;
      }
    }
  }

  .toggle-mobile-menu {
    display: none;
    cursor: pointer;
    background-color: transparent;
    border: 0;

    p {
      color: $light;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 1px;
      margin-right: 10px;
      transition: all 0.2s ease;
      position: relative;
      top: 2px;
    }

    .burger {
      span {
        display: block;
        width: 25px;
        height: 3px;
        background-color: $light;
        margin-bottom: 5px;
        transition: all 0.2s ease;
      }
    }
  }

  .about-link.is-hover + .sub-menu {
    top: 55px;
    opacity: 1;
    height: auto;
  }

  .services-link.is-hover + .services-sub {
    top: 55px;
    opacity: 1;
    height: auto;
  }

  .sub-menu {
    z-index: -1;
    height: 0;
    overflow: hidden;
    background-color: #161717;
    width: fit-content;
    position: absolute;
    transition: top 0.2s ease, opacity 0.2s ease, height 0.2s ease 0.2s;
    top: 60px;
    opacity: 0;

    a {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      padding: 12px 20px;
      font-size: 12px;
      border-bottom: 1px solid rgba(255,255,255,0.1);
    }

    &.about-sub {
      left: 10px;
    }

    &.services-sub {
      left: 110px;
    }

  }

  .right {
    display: flex;
    align-items: center;

    .lang {
      font-size: 14px;
      margin-right: 20px;

      &:hover {
        color: $red;
      }
    }
  }

  &.fixed {
    padding: 1vw 5vw;
    background-color: #fff;
    .logo {
      width: 100px;
      opacity: 0;
      display: none;
    }
    .logo-colors {
      opacity: 1;
      display: block;
    }
    a {
      color: $dark;

      &.is-active {
        color: $red;
      }
    }
    .button {
      border: 1px solid $dark;
      p {
        color: $dark;
      }
      &:hover {
        p {
          color: $light;
        }
        &::before {
          background-color: $dark;
        }
      }
    }
    .sub-menu {
      top: 51px;
      a {
        color: $light;

        &:hover {
          color: $red;
        }
      }
    }
    .about-link.is-hover + .sub-menu {
      top: 51px;
      opacity: 1;
    }

    .services-link.is-hover + .services-sub {
      top: 51px;
      opacity: 1;
    }
    .toggle-mobile-menu {
      p {
        color: $dark;
      }
      .burger {
        span {
          background-color: $dark;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    nav {
      right: 0;
    }
  }
  @media only screen and (max-width: 1080px) {
    nav {
      display: none;
    }
    .sub-menu {
      display: none;
    }
    .right {
      display: none;
    }
    .toggle-mobile-menu {
      display: flex;
    }
    &.fixed {
      padding: 1.5vw 5vw;
    }
  }
  @media only screen and (max-width: 500px) {
    padding: 5vw 8vw;

    .logo {
      width: 80px;
    }

    .logo-colors {
      width: 80px;
    }

    .toggle-mobile-menu {
      p {
        font-size: 14px;
      }
    }

    &.fixed {
      padding: 3vw 5vw;
      .logo-colors {
        img {
          width: 60px;
        }
      }
    }
  }
}

.mobile-menu-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  .nav-wrap {
    width: 40vw;
    height: 100%;
    background-color: $light;
    position: absolute;
    right: -40vw;
    top: 0;
    z-index: 2;
    transition: all 0.2s ease;
    padding: 5vw 4vw;
    overflow-y: scroll;

    .close {
      position: absolute;
      top: 2vw;
      right: 2vw;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    .logo-colors {
      img {
        width: 125px;
        margin-bottom: 2vw;
      }
    }

    .about-sub-menu {
      position: relative;
      z-index: -1;

      width: 100%;
      height: 0;
      overflow: hidden;
      transition: all 0.2s ease 0.5s;

      &.show {
        height: 230px;
        z-index: 2;
      }
    }

    .services-sub-menu {
      width: 100%;
      height: 0;
      overflow: hidden;
      transition: all 0.2s ease;

      &.show {
        height: 100px;
      }
    }

    .mobile-link {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      color: $dark;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: none;
      padding: 18px 20px;
      margin-bottom: 3px;
      cursor: pointer;
      transition: all 0.2s ease;
      line-height: 1.2;

      &.is-active {
        color: $red;
      }

      p {
        margin: 0;
        padding: 0;
      }

      img {
        width: 10px;
      }

      &:hover, &.dark {
        background-color: $dark;
        color: $light;
      }

      &.dark {
        padding: 15px 20px;
        margin-bottom: 0;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2vw;

      .lang {
        color: $dark;
        text-decoration: none;
      }
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $dark;
    opacity: 0;
    z-index: 1;
    transition: all 0.2s ease;
  }

  &.show {
    z-index: 2000000000;

    .nav-wrap {
      right: 0;
    }

    .overlay {
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 940px) {
    .nav-wrap {
      right: -50vw;
      width: 50vw;
    }
  }
  @media only screen and (max-width: 700px) {
    .nav-wrap {
      width: 75vw;
      right: -75vw;
      padding: 8vw 7vw;
    }
  }
  @media only screen and (max-width: 500px) {
    .nav-wrap {
      width: 100vw;
      right: -100vw;
      .close {
        right: 5vw;
        top: 5vw;
      }
      .logo-colors {
        img {
          width: 100px;
          margin-bottom: 5vw;
        }
      }
      .bottom {
        margin-top: 5vw;
      }
    }
  }
}
