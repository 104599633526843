.hero-half-half {
	width: 100%;
	display: flex;
	position: relative;

	.data-wrap {
		width: 50%;
		height: 80vh;
		background-color: $dark;
		padding: 5vw;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		position: relative;

		.data {
			position: relative;
			top: 2vw;
		}

		.tiny-title {
			color: $light;
			margin-bottom: 20px;
		}

		.big-title {
			color: $light;
			width: 100%;
		}

		.button {
			margin-top: 25px;
		}

		.timeline {
			width: calc(100% - 10vw);
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: absolute;
			left: 5vw;
			bottom: 3vw;

			.line {
				width: 100%;
				height: 1px;
				position: absolute;
				background-color: $light;
			}

			.regular-text {
				color: $light;
				font-size: 14px;
				text-transform: uppercase;
				width: fit-content;
				position: relative;
				z-index: 10;
				background-color: $dark;

				&:first-child {
					padding-right: 20px;
				}
				&:last-child {
					text-align: right;
					padding-left: 20px;
				}
			}
		}
	}

	&.contact {
		.box-contact {
			width: 220px;
		}
		.big-title {
			margin-bottom: 2vw;
		}
		.mail-wrap {
			display: flex;
			margin-top: 2vw;

			.mail {
				&.full {
					width: 100%;
					a {
						width: 100%;
					}
				}
				&.no-mlr {
					margin-left: 0;
					margin-right: 0;
				}
				p {
					color: $light;
					font-size: 14px;
				}
				&:last-child {
					margin-left: 1vw;
					&.no-mlr {
						margin-left: 0;
						margin-right: 0;
					}
				}
			}
		} 
	}

	.img {
		width: 50%;
		height: 80vh;
		background-size: cover;
		background-position: center center;
		position: relative;
		overflow: hidden;

		.safe {
			width: 20vw;
			max-width: 110px;
			position: absolute;
			right: 5vw;
			bottom: 100px;
			z-index: 10;
		}

		video {
			position: absolute;
			right: 0;
			bottom: 0;
			min-width: 100%;
			min-height: 100%;
			z-index: 1;
		}

		&:before {
			content:'';
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.4);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		&.projets {
			background-color: $dark;
		}
		&.projet {
			background-image: url('/img/hero-projet.jpg');
		}
		&.carrieres {
			background-color: $dark;
		}
		&.publications {
			background-image: url('/img/hero-publications.jpg');
		}
		&.publication-details {
			background-image: url('/img/article-hero.jpg');
		}
		&.contact {
			background-color: $dark;
		}
	}

	.letter-r {
		position: absolute;
		right: 0px;
		bottom: 0vw;
		width: 22vw;
		z-index: 10;
	}

	&.carrieres {
		.data-wrap {
			.safe {
				width: 20vw;
				max-width: 80px;
				position: absolute;
				right: inherit;
				left: 5vw;
				bottom: 50px;
				z-index: 10;
			}
		}
	}

	@media only screen and (max-width: 1100px) {
		&.contact {
			.mail-wrap {
				flex-wrap: wrap;

				.mail {
					width: 100%;

					&.no-mlr {
						margin-left: 0;
						margin-right: 0;
					}

					&:last-child {
						margin-left: 0;
						margin-top: 2vw;
						&.no-mlr {
						margin-left: 0;
						margin-right: 0;
					}
					}
				}
			}
		}
	}

	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		.data-wrap {
			width: 100%;
			height: auto;
			order: 1;
			padding: 10vw 8vw 10vw 8vw;

			&.spacing {
				padding: 10vw 8vw 18vw 8vw;
			}

			.data {
				top: 0;
			}
		}
		.img {
			width: 100%;
			height: 50vh;
			order: 0;
		}

		&.contact {
			.mail-wrap {
				flex-wrap: inherit;

				.mail {
					width: 50%;

					&.full {
						width: 100%;
						a {
							width: 100%;
						}
					}

					&.no-mlr {
						margin-left: 0;
						margin-right: 0;
					}

					&:last-child {
						margin-left: 10px;
						margin-top: 0vw;
						&.no-mlr {
						margin-left: 0;
						margin-right: 0;
					}
					}
				}
			}
		}

		&.carrieres {
			.data-wrap {
				.safe {
					width: 20vw;
					max-width: 60px;
					position: absolute;
					left: inherit;
					bottom: inherit;
					right: 5vw;
					top: 30px;
					z-index: 10;
				}
			}
		}
	}

	@media only screen and (max-width: 500px) {
		&.contact {
			.box-contact {
				width: 100%;
			}
			.mail-wrap {
				flex-wrap: wrap;

				.mail {
					width: 100%;
					
					&.no-mlr {
						margin-left: 0;
						margin-right: 0;
					}

					&:last-child {
						margin-left: 0;
						margin-top: 2vw;
						&.no-mlr {
						margin-left: 0;
						margin-right: 0;
					}
					}
				}
			}
		}
	}
}

.map-wrap {
	width: 100%;
	padding: 0 8vw;
	margin-top: -6vw;
	position: relative;
	z-index: 100;

	@media (max-width: 974px) {
		padding: 0;
		width: 100%;
		margin-top: 0;
	}

	.map-wrapper {
		width: 100%;
		height: 100%;
		position: relative;
		border: 3px solid #fff;

		@media (max-width: 974px) {
			border: none;
			min-height: 455px;
		}
	}

	.map-ph {
		opacity: 0;
	}

	.map {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		background: #e2e2e2;
	}

	.legende {
		display: flex;
		justify-content: center;
		margin-top: 20px;

		img {
			width: 30px;
			margin-right: 15px;
		}

		.spacer {
			width: 5vw;
		}
	}
}

.stats-wrap {
	width: 100%;
	padding: 5vw 8vw 0 8vw;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.data {
		width: 49%;

		.medium-title {
			margin: 25px 0;
		}
	}

	.stats {
		width: 45%;
		display: flex;
		flex-wrap: wrap;

		.stat {
			width: 50%;
			text-align: center;
			margin: 2vw 0;

			.number {
				color: $red;
				font-weight: 800;
				font-size: 5vw;
				margin-bottom: 10px;
			}

			.regular-text {
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 1px;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		padding: 10vw 8vw;
		.data {
			width: 100%;
			margin-bottom: 5vw;
		}
		.stats {
			width: 100%;
		}
	}
}

.temoignages-block {
	width: 100%;
	padding: 0 8vw;
	position: relative;

	&.spacing {
		padding-top: 8vw;
		padding-bottom: 8vw;
	}

	.letter-s {
		position: absolute;
		left: -2vw;
		bottom: -8vw;
		width: 15vw;
		z-index: 1;
	}

	.main-carousel {
		width: 100%;

		.carousel-cell {
			width: 100%;
			display: flex;
			align-items: center;

			.img {
				width: 30%;
			}

			.data {
				width: 70%;
				padding-left: 5vw;
				position: relative;
				top: -2vw;

				.quote {
					font-size: 30px;
					font-weight: 300;
					line-height: 1.3;
					margin-bottom: 20px;
				}

				.regular-text {
					line-height: 1;
					&.poste {
						font-size: 14px;
						opacity: 0.5;
						margin-top: 5px;
					}
				}
			}
		}
	}

	.arrows {
		position: absolute;
		right: 8vw;
		bottom: 8vw;
		display: flex;
	}

	@media only screen and (max-width: 1024px) {
		.main-carousel .carousel-cell .data .quote {
			font-size: 20px;
		}
	}

	@media only screen and (max-width: 800px) {
		.main-carousel {
			.carousel-cell {
				flex-wrap: wrap;

				.data {
					width: 100%;
					top: 0;
					margin-top: 5vw;
					padding-left: 0;
				}
			}
		}
		.arrows {
			bottom: 5vw;
		}
	}
}

.gm-style-iw.gm-style-iw-c,
.gm-style-iw-d {
	overflow: hidden !important;
	padding: 0 !important;
	margin: 0 !important;
}

.gm-ui-hover-effect {
	background: #fff !important;
	border-radius: 50%;
	top: 8px !important;
	right: 8px !important;
}

.google-marker-details {
	padding: 0 0 20px 0;
	margin: 0;
}

.map-place-heading {
  margin-bottom: 5px;
  font-family: $fontText;
  font-size: 16px;
  line-height: 1.1;
  color: #3D441D;
  padding: 10px 20px;
  padding-bottom: 0;
  padding-top: 20px;
  margin: 0;
  font-weight: 600;
}

.map-place-text {
  font-family: $fontText;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  margin: 0;
  padding: 10px 20px;
}

.map-link {
	font-family: $fontText;
	padding: 10px 20px;
	padding-bottom: 20px;
	color: $red;
	text-decoration: none;
	font-weight: 600;

	&.hide-url {
		display: none;
	}
}

.encours .map-marker-img {
	
}

.map-marker-img {
	display: block;
	width: 100%;
	max-height: 200px;
	object-fit: cover;
}

.gm-style-iw.gm-style-iw-c {
  max-width: 325px !important;
  width: 325px !important;
  padding: 0!important;
  box-shadow: none;
  overflow: hidden;
}

.gm-style-iw-t:after {
  transform: translate(-210%, -55%) rotate(-45deg) !important;
}
