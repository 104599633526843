.main-footer {
	padding: 5vw;
	position: relative;
	.wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;
		background-color: $dark;
		padding: 5vw;
		width: 100%;

		.logo {
			width: 100%;
			margin-bottom: 3vw;
			img {
				width: 200px;
				opacity: 1;
			}
		}

		.contact {
			width: 40%;

			.regular-text {
				color: $light;
				font-size: 15px;
			}

			.social {
				width: 30px;
				display: block;
				margin-top: 10px;
			}

			&.social-box {
				width: 30px;
			}
		}
	}
	.letter-i {
		position: absolute;
		right: 10vw;
		top: -5vw;
		width: 8vw;
		z-index: 1;
	}

	@media only screen and (max-width: 1000px) {
		.wrap {
			.contact {
				width: 45%;

				.regular-text {
					font-size: 13px;
				}
			}
			.logo {
				margin-bottom: 5vw;
				img {
					width: 100px;
				}
			}
		}
	}
	@media only screen and (max-width: 600px) {
		.wrap {
			.contact {
				width: 100%;
				margin-bottom: 5vw;
			}
		}
	}
}

.box-contact {
	height: 60px;
	display: flex;
	align-items: center;
	border: 1px solid rgba(255,255,255,0.2);
	text-decoration: none;
	padding: 20px;
	margin-top: 10px;
	p {
		color: $light;
		font-size: 14px;
	}
	
	img {
		width: 15px;
		margin-right: 20px;
	}

	@media only screen and (max-width: 1000px) {
		height: 50px;
		padding: 15px;
		p {
			font-size: 12px;
		}
	}
}

.no-i-footer {
	.main-footer {
		.letter-i {
			display: none;
		}
	}
}