.identite-block {
	background-color: $dark;
	padding-left: 8vw;
	padding-bottom: 0;
	margin-top: 15vw;
	position: relative;

	.letter-t {
		position: absolute;
		left: 0px;
		top: -15vw;
		width: 20vw;
		z-index: 1;
	}

	.top {
		display: flex;
		align-items: flex-end;
		margin-bottom: 5vw;
		position: relative;
		top: -8vw;

		.left {
			width: calc(50% - 4.1vw);
			padding-right: 5vw;
		}

		.right {
			width: calc(50% + 4.1vw);

			video {
				width: 100%;
				height: auto;
			}
		}
	}

	.medium-title {
		color: $light;
		margin-bottom: 25px;
	}

	.tiny-title {
		color: $light;
		margin-bottom: 25px;
	}

	.regular-text {
		color: $light;
	}

	.valeurs {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-right: 8vw;
		text-align: center;
		position: relative;
		top: -8vw;

		.title-wrap {
			width: 100%;
			.tiny-title {
				width: fit-content;
			}
		}

		.box {
			width: calc(25% - 10px);

			.img {
				width: 100%;
				img {
					width: 100%;
				}
			}

			.medium-title {
				font-size: 18px;
				margin-bottom: 10px;
				margin-top: 20px;
			}

			.regular-text {
				line-height: 1.5;
				padding: 0 2vw;
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		padding-bottom: 8vw;
		.letter-t {
			width: 15vw;
		}
		.top {
			top: 0;
		}
		.valeurs {
			top: 0;
		}
	}

	@media only screen and (max-width: 800px) {
		margin-top: 20vw;
		padding: 0;
		.top {
			flex-wrap: wrap;
			padding: 8vw;
			.left {
				width: 100%;
				padding-right: 0;
				margin-bottom: 5vw;
			}

			.right {
				width: 100%;
			}
		}
		.valeurs {
			padding: 0 8vw 8vw 8vw;
			.box {
				width: calc(50% - 10px);
				margin-bottom: 5vw;

				.medium-title {
					font-size: 14px;
					margin-bottom: 10px;
					margin-top: 15px;
				}

				.regular-text {
					line-height: 1.5;
					padding: 0 2vw;
				}
			}
		}
	}
}

.slider {
	width: 100%;
	padding: 5vw 0;

	&.spacing-bottom {
		margin-bottom: 3vw;
	}

	.main-carousel {
		width: 100%;
		margin-top: 3vw;

		.carousel-cell {
			width: calc(100% - 40vw);
			max-width: 860px;
			margin: 0 10px;
			opacity: 0.3;
			transition: all 0.2s ease;
			position: relative;

			&.is-selected {
				opacity: 1;
			}

			.name {
				background-color: #fff;
				padding: 15px 20px;
				position: absolute;
				top: 20px;
				left: 20px;

				p {
					font-size: 14px;
					font-weight: 400;
					text-transform: uppercase;
					letter-spacing: 1px;
				}
			}

			.logo {
				width: 150px;
				position: absolute;
				left: 20px;
				bottom: 20px
			}

			.button {
				position: absolute;
				right: 20px;
				bottom: 20px;
				background-color: #fff;

				p {
					color: $dark;
				}
			}
		}
	}

	.arrow-position {
		position: relative;

		.arrow {
			&.left {
				position: absolute;
			    left: 0;
			    top: 50%;
			    transform: translate(0,-50%);
			    margin-left: -1px;
			}
			&.right {
				position: absolute;
			    right: 0;
			    top: 50%;
			    transform: translate(0,-50%);
			    margin-left: 0;
			    margin-right: -1px;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		padding-top: 8vw;
		margin-bottom: 5vw;
		.main-carousel .carousel-cell .name {
			padding: 15px 0;
			top: 0px;
			left: 0;
			position: relative;
			p {
				font-size: 12px;
			}
		}
		.main-carousel .carousel-cell .logo {
			width: 80px;
			left: 0px;
			bottom: 0px;
		}
		.main-carousel .carousel-cell .button {
			right: 0px;
			bottom: 0px;
		}
	}
}

.slider-content {
	width: 100%;
	background-color: $dark;
	margin-bottom: 10vw;
	margin-top: 3vw;
	position: relative;

	.flickity-viewport {
		overflow: inherit;
	}

	.main-carousel {
		width: 100%;
		overflow: hidden;
	}

	.arrow-position {
		position: relative;

		.arrow {
			&.left {
				position: absolute;
			    left: 0;
			    top: 50%;
			    transform: translate(0,-50%);
			    margin-left: -1px;
			}
			&.right {
				position: absolute;
			    right: 0;
			    top: 50%;
			    transform: translate(0,-50%);
			    margin-left: 0;
			    margin-right: -1px;
			}
		}
	}

	.carousel-cell {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;

		.content {
			width: 50%;
			padding: 0 5vw 0 8vw;

			.medium-title {
				color: $light;
				margin-top: 25px;
				margin-bottom: 25px;
			}

			.tiny-title, .regular-text {
				color: $light;
			}
		}

		.img {
			width: 50%;
			position: relative;
		}
	}

	.button-wrap {
		display: flex;
		position: absolute;
		left: 8vw;
		bottom: 30px;

		.arrow {
			margin-left: 0;
			margin-right: 15px;
		}
	}

	.letter-s {
		position: absolute;
		right: -2vw;
		bottom: -10vw;
		width: 15vw;
		z-index: 1;
	}

	@media only screen and (max-width: 1200px) {
		.carousel-cell {
			.content {
				padding: 0 5vw 0 8vw;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		.carousel-cell {
			flex-wrap: wrap;

			.content {
				width: 100%;
				order: 1;
				padding: 8vw 8vw 20vw 8vw;
			}

			.img {
				width: 100%;
				order: 0;
				top: 0;
			}
		}
	}
}

.slider-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0 8vw;

	.left {
		.regular-text {
			width: 60%;
			margin-top: 25px;
		}
	}

	.medium-title {
		margin-top: 25px;
	}

	.right {
		display: flex;
	}

	@media only screen and (max-width: 800px) {
		margin-bottom: 5vw;

		.left {
			.regular-text {
				width: 90%;
			}
		}
	}
}

.main-carousel-about {
	max-height: 900px;
}
